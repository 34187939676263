import React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TheHeader from "../components/theHeader";
import { useNavigate } from "react-router-dom";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { TabPanelTypes } from "../models/types";
import UserInfo from "../components/user-info";
import UserProducts from "../components/user-products";
import { UserTabStore } from "../store/store";

function TabPanel(props: TabPanelTypes) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function UserPage() {
  const navigate = useNavigate();
  const theme = useTheme();

  const [value, setValue] = UserTabStore((state) => [
    state.tab,
    state.updateTab,
  ]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  // Custom style for inactive tab
  const tabStyle = (isActive: boolean) => ({
    color: isActive ? theme.palette.secondary.main : theme.palette.grey[500],
  });

  return (
    <div style={{ height: "100%" }}>
      <TheHeader
        headerText="User Page"
        icon="arrow_back"
        iconPress={() => (value === 1 ? setValue(0) : navigate("/"))}
      />
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            centered
            variant="fullWidth"
            value={value}
            onChange={handleChange}
            aria-label="user info and registered devices"
            textColor="secondary"
            indicatorColor="secondary"
          >
            <Tab
              label="User info"
              {...a11yProps(0)}
              sx={() => tabStyle(value === 0)}
            />
            <Tab
              label="Registered devices"
              {...a11yProps(1)}
              sx={() => tabStyle(value === 1)}
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <UserInfo />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <UserProducts />
        </TabPanel>
      </Box>
    </div>
  );
}
