import React, { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import styled from "styled-components";
import { getUserProductsData } from "../API/firebase";
import { UserProductTypes } from "../models/types";
import NoProductData from "./no-product-data";
import UserProductsDataGrid from "./UserProductsDataGrid";

const UserProductsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: calc(100vh - 250px);
  width: 100%;
  overflow-y: auto;
  padding: 20px;
`;

const UserProducts: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [products, setProducts] = useState<UserProductTypes[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedProducts = await getUserProductsData();
        setProducts(fetchedProducts);
      } catch (error) {
        console.error("Error fetching products:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <UserProductsContainer>
        <CircularProgress />
      </UserProductsContainer>
    );
  }

  if (!products || products.length === 0) {
    return (
      <UserProductsContainer>
        <NoProductData />
      </UserProductsContainer>
    );
  }

  return (
    <UserProductsContainer>
      <UserProductsDataGrid />
    </UserProductsContainer>
  );
};

export default UserProducts;
