import React, { useEffect, useState } from "react";
import {
  DataGrid,
  GridColDef,
  DataGridProps,
  GridRowSelectionModel,
} from "@mui/x-data-grid";
import styled from "styled-components";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";

import { getUserProductsData, removeProduct } from "../API/firebase";
import { UserProductTypes } from "../models/types";

const StyledDataGrid = styled((props: DataGridProps) => (
  <DataGrid {...props} />
))`
  &.MuiDataGrid-root {
    background-color: none;
    color: #ffffff;
    border: 1px solid #ffffff;

    /* Column header area */
    .MuiDataGrid-columnHeaders {
      background-color: none;
      color: #424242;
      border-bottom: 1px solid #ffffff;
    }

    /* Row hover */
    .MuiDataGrid-row:hover {
      background-color: #ffffff;
      color: #424242;
    }

    /* Horizontal borders for each cell */
    .MuiDataGrid-cell {
      border-bottom: 1px solid #ffffff;
    }

    /* Add vertical borders for column headers and cells */
    .MuiDataGrid-columnHeader,
    .MuiDataGrid-cell {
      border-right: 1px solid #ffffff;
    }

    /* Remove the right border for the last column (headers and cells) */
    .MuiDataGrid-columnHeader:last-of-type,
    .MuiDataGrid-cell:last-of-type {
      border-right: none;
    }

    /* Pagination styling */
    .MuiTablePagination-root {
      color: #ffffff;
    }

    /* Sort icon, column separator, etc. */
    .MuiDataGrid-iconSeparator {
      color: #ffffff;
    }
  }
`;

const GridContainer = styled.div`
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
`;

type UserProductDataGridType = UserProductTypes & { id: number };

const removeProductFromDB = async (serialNo: string) => {
  await removeProduct(serialNo);
  //console.log("Removing product with serialNo:", serialNo);
};

const UserProductsDataGrid: React.FC = () => {
  const [products, setProducts] = useState<UserProductDataGridType[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([]);

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const columns: GridColDef[] = [
    {
      field: "artome_product",
      headerName: "Product",
      flex: 1,
      maxWidth: 200,
    },
    {
      field: "artome_serial_no",
      headerName: "Artome Serial #",
      flex: 1,
      maxWidth: 250,
    },
    {
      field: "epson_serial_no",
      headerName: "Projector Serial #",
      flex: 1,
      maxWidth: 250,
    },
    {
      field: "setup_date",
      headerName: "Registered Date",
      flex: 1,
      minWidth: 160,
      maxWidth: 300,
    },
  ];

  useEffect(() => {
    const fetchUserProducts = async () => {
      try {
        const userProducts = await getUserProductsData();

        const mapped: UserProductDataGridType[] = userProducts.map(
          (item, index) => ({
            ...item,
            id: index,
            setup_date: item.setup_date
              ? new Date(item.setup_date)
                  .toISOString()
                  .split("T")[0]
                  .replace(/-/g, "/") // Converts to YYYY/MM/DD
              : "",
          })
        );

        setProducts(mapped);
      } catch (error) {
        console.error("Error fetching user products:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserProducts();
  }, []);

  const handleRowSelection = (newSelectionModel: GridRowSelectionModel) => {
    setRowSelectionModel(newSelectionModel);
  };

  const handleBulkDeleteClick = () => {
    if (rowSelectionModel.length === 0) return;
    setDeleteDialogOpen(true);
  };

  const handleConfirmBulkDelete = async () => {
    try {
      const selectedProducts = products.filter((product) =>
        rowSelectionModel.includes(product.id)
      );

      for (const prod of selectedProducts) {
        if (prod.artome_serial_no) {
          await removeProductFromDB(prod.artome_serial_no);
        }
      }

      setProducts((prev) =>
        prev.filter((prod) => !rowSelectionModel.includes(prod.id))
      );

      setRowSelectionModel([]);
    } catch (error) {
      console.error("Error deleting products:", error);
    } finally {
      setDeleteDialogOpen(false);
    }
  };

  const handleDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  return (
    <GridContainer>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          {/* Bulk Delete Button */}
          <div style={{ marginBottom: "1rem" }}>
            <Button
              variant="contained"
              color="error"
              onClick={handleBulkDeleteClick}
              disabled={rowSelectionModel.length === 0}
            >
              Delete Selected
            </Button>
          </div>

          <StyledDataGrid
            rows={products}
            columns={columns}
            pageSizeOptions={[5, 10, 20]}
            initialState={{
              pagination: {
                paginationModel: { pageSize: 10, page: 0 },
              },
            }}
            checkboxSelection
            onRowSelectionModelChange={handleRowSelection}
            rowSelectionModel={rowSelectionModel}
            disableRowSelectionOnClick
          />

          <Dialog
            open={deleteDialogOpen}
            onClose={handleDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              Confirm Bulk Deletion
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete the selected products?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleConfirmBulkDelete} color="error" autoFocus>
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </GridContainer>
  );
};

export default UserProductsDataGrid;
