import React, { FC, useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import logo from "../images/logo/logo.png";
import logoMobile from "../images/logo/logo-mobile.png";
import { HeaderType } from "../models/types";
import styled from "styled-components";
import { useNavigate, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";

const HeaderWrapper = styled(Box)`
  .regular-logo {
    display: block;
  }

  .small-logo {
    display: none;
  }

  @media only screen and (max-width: 299px) {
    .regular-logo {
      display: none;
    }

    .small-logo {
      display: block;
    }
  }

  @media only screen and (min-width: 300px) and (max-width: 767px) {
    .regular-logo {
      display: none;
    }

    .small-logo {
      display: block;
    }
  }
`;

const HeaderContainer = styled(Box)`
  position: relative;
  top: 0;
  z-index: 9999999;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  background-color: #000000;
  border-bottom: solid 1px rgba(255, 255, 255, 0.3);
  padding-left: 30px;
  transition: transform 0.3s ease-in-out;
`;

const HeaderLogoContainer = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 60px;
  cursor: pointer;

  img {
    height: 50%;
  }
`;

const HeaderTextContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  flex-grow: 1;
  margin-left: auto;
  margin-right: auto;

  span {
    text-transform: uppercase;
    color: #ffffff;
    font-size: 0.8em;
  }
`;

const HeaderButtonContainer = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 60px;

  .css-6z4he2-MuiButtonBase-root-MuiButton-root {
    box-shadow: none !important;
    height: 60px !important;
    width: 60px !important;
  }
`;

const HeaderBottomTitleContainer = styled(Box)`
  display: flex;
  top: 60px;
  z-index: 9999999;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 30px;
  padding: 0px 30px;
  background-color: #000000;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);

  span {
    color: #ffffff;
    font-size: 0.9em;
  }
`;

const TheHeader: FC<HeaderType> = ({ headerText, icon, iconPress }) => {
  const [hideHeader, setHideHeader] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const shouldShowRegisterButton =
    location.pathname !== "/register-artome-serial-no" &&
    location.pathname !== "/register-projector-serial-no" &&
    location.pathname !== "/register-done" &&
    location.pathname !== "/register-owner";

  useEffect(() => {
    let lastScrollTop = 0;

    const handleScroll = () => {
      const currentScrollTop = window.pageYOffset;
      console.log("Scroll position:", currentScrollTop); // Debugging log
      console.log("Header state before:", hideHeader); // Debugging log
      setHideHeader(currentScrollTop > lastScrollTop);
      console.log("Header state after:", hideHeader); // Debugging log
      lastScrollTop = currentScrollTop <= 0 ? 0 : currentScrollTop;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [hideHeader]);

  return (
    <HeaderWrapper>
      <HeaderContainer>
        <HeaderLogoContainer onClick={() => navigate("/")}>
          <img src={logo} alt="Artome logo" className="regular-logo" />
          <img src={logoMobile} alt="Artome logo" className="small-logo" />
        </HeaderLogoContainer>

        <HeaderTextContainer>
          <span>Artome Installer</span>
        </HeaderTextContainer>

        <HeaderButtonContainer>
          <Button
            onClick={iconPress}
            aria-label="save"
            variant="contained"
            sx={{
              width: 60,
              height: 60,
              borderRadius: 0,
              border: "1px solid",
              borderColor: "primary.main",
              "& .MuiButton-startIcon": { margin: 0 },
            }}
            startIcon={<Icon color="secondary">{icon}</Icon>}
          ></Button>
        </HeaderButtonContainer>
      </HeaderContainer>

      <HeaderBottomTitleContainer>
        <span>{headerText}</span>
        {shouldShowRegisterButton && (
          <Button
            size="small"
            variant="text"
            color="secondary"
            endIcon={<Icon>chevron_right</Icon>}
            onClick={() => navigate("/register-artome-serial-no")}
          >
            Register the Product
          </Button>
        )}
      </HeaderBottomTitleContainer>
    </HeaderWrapper>
  );
};

export default TheHeader;
