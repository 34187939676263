import { Paths } from "../enums/path.enum";
import { Products } from "../enums/product.enum";
import { Styles } from "../enums/style.enum";

import s1Logo from "../images/S1/logo/s-series-logo.svg";
import m10Logo from "../images/M10/logo/m10-logo-white.svg";
import x20Logo from "../images/X20/logo/x20-logo-white.svg";

import s1Product from "../images/S1/Product/s1-product-only.png";
import m10Product from "../images/M10/Product/m10-product-only.png";
import x20Product from "../images/X20/Product/x20-product-only.png";

import s1Selected from "../images/S1/Product/S1_selected.png";
import m10Selected from "../images/M10/Product/M10_selected.png";
import x20Selected from "../images/X20/Product/X20_selected.png";

export const productItems = [
  {
    logo: s1Logo,
    image: s1Product,
    hoverImage: s1Selected,
    name: Products.s1,
    path: Paths.s1Setup,
    styles: Styles.productItemContainer,
    buttonText: "select",
    secondary: true,
    success: false,
  },
  {
    logo: m10Logo,
    image: m10Product,
    hoverImage: m10Selected,
    name: Products.m10,
    path: Paths.m10Setup,
    styles: Styles.productItemContainer,
    buttonText: "select",
    secondary: false,
    success: false,
  },
  {
    logo: x20Logo,
    image: x20Product,
    hoverImage: x20Selected,
    name: Products.x20,
    path: Paths.x20Setup,
    styles: Styles.productItemContainer,
    buttonText: "select",
    secondary: true,
    success: false,
  },
];
