import React, { useState } from "react";
import sernoImage from "../../images/X20/serial-no/x20-serial-no.png";
import { useNavigate } from "react-router-dom";
import SerialNumberItem from "../../components/serial-number-item";
import { ProductNameStore, SerialNumbersStore } from "../../store/store";
import TheHeader from "../../components/theHeader";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { SerialsContainer } from "../../styles/serial-number-styles";

function ArtomeSno() {
  const [updateArtomeSerialNo] = SerialNumbersStore((state) => [
    state.updateArtomeSerialNo,
  ]);
  const [updateProductName] = ProductNameStore((state) => [
    state.updateProductName,
  ]);

  const [serialNumber, setSerialNumber] = useState("");
  const [enable, setEnable] = useState(false);
  const navigate = useNavigate();

  // Updated patterns to include S02
  // New format: e.g. S02232XX00000 -> prefix + 3 digits + 2 alphanumeric + 5 digits
  const pattern1 = /^(S1|S01|S02|M10|X20|P01)\d{3}[A-Za-z0-9]{2}\d{5}$/i;
  // Old format - added S02 if you want to allow old format for S02 as well
  const pattern2 = /^(S1|S01|S02|M10|X20|P01)\d{4,5}X$/i;

  const isValidSerialNumber = (input: string) => {
    return pattern1.test(input) || pattern2.test(input);
  };

  const handleSerialNumberChange = (input: string) => {
    setSerialNumber(input);
    setEnable(isValidSerialNumber(input));
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (enable) {
      const productName = formatProductName(serialNumber);
      updateArtomeSerialNo(serialNumber);
      updateProductName(productName);
      navigate("/register-projector-serial-no");
    }
  };

  /**
   * Extract matched device prefix and convert it into a friendly product name.
   */
  const formatProductName = (serial: string): string => {
    const match1 = serial.match(pattern1);
    const match2 = serial.match(pattern2);

    if (match1) {
      return reformatProductName(match1[1]);
    }
    if (match2) {
      return reformatProductName(match2[1]);
    }
    return "";
  };

  /**
   * Reformat recognized product codes to friendly names.
   */
  const reformatProductName = (productName: string): string => {
    const reformattedProductName = productName.toUpperCase();

    if (reformattedProductName === "S01" || reformattedProductName === "S1") {
      return "S1";
    }
    if (reformattedProductName === "S02") {
      return "S2";
    }
    if (reformattedProductName === "P01") {
      // Friendly name for P01 devices
      return "S10PPS";
    }
    return productName;
  };

  return (
    <>
      <TheHeader
        headerText={"Artome serial number"}
        icon={"home"}
        iconPress={(): void => navigate("/")}
      />
      <Container>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ minHeight: "100vh" }}
        >
          <Grid
            component={Box}
            item
            mobile={12}
            tablet={8}
            laptop={6}
            desktop={4}
          >
            <SerialsContainer>
              <SerialNumberItem
                showBackButton={false}
                image={sernoImage}
                text={""}
                buttonText={"Next"}
                secondary={true}
                inputOnChange={handleSerialNumberChange}
                handleSubmit={handleSubmit}
                enableButton={enable}
                backButtonRoute="/"
                inputLabel="Artome Serial #"
                inputName="asno"
              />
            </SerialsContainer>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default ArtomeSno;
